<template>
  <div class="bg-sky-100">
    <div class="container py-14 lg:py-20">
      <div class="flex items-center justify-between mb-10">
        <div>
          <AppTitle text="خدماتنا" />
        </div>
      </div>

      <shimmer-services v-if="loading" />

      <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
        <ServiceCard v-for="item in services" :key="item?.id" :item="item" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const {services, loading} = useService();
</script>
