<template>
  <div
    class="mainSection pt-40 pb-24 lg:pt-48 text-white !bg-cover bg-blue-900"
  >
    <div class="container">
      <div class="grid lg:grid-cols-2 gap-10 items-center">
        <div class="order-2 lg:order-1">
          <h3
            class="relative w-fit after-content[''] after:absolute after:bottom-0 after:start-0 after:w-2/5 after:h-2 after:bg-sky-400 text-2xl md:text-3xl lg:text-5xl font-bold pb-4 mb-6 lg:mb-10"
          >
            {{ hero?.content }}
          </h3>

          <a
            :href="hero?.profile_pdf?.url"
            target="_blank"
            class="flex items-center gap-x-3 w-max py-3 px-4 md:py-4 md:px-8 border-2 border-white rounded-lg mt-10 text-xs sm:text-sm lg:text-base transition-all duration-200 hover:bg-white hover:text-gray-500 font-semibold"
          >
            تحميل ملف الشركة
            <svg-icon name="download" class="w-4 h-4 fill-current" />
          </a>
        </div>
        <div class="overflow-hidden order-1 lg:order-2">
          <swiper-wrapper
            :items="[hero?.image?.url]"
            :swiperOptions="swiperOptions"
          >
            <template v-slot="{item}">
              <app-image :src="item" alt="hero image" ratio="3-2" fit="fill" />
            </template>
          </swiper-wrapper>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
const {hero} = useHero();

const swiperOptions = computed(() => {
  return {
    breakpoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
    },
    loop: true,
    autoplay: {
      delay: 2000,
      disableOnInteraction: true,
    },
  };
});
</script>
