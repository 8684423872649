<template>
  <div class="bg-white">
    <div class="container py-14 lg:py-20">
      <div class="flex items-center justify-between mb-10">
        <div>
          <AppTitle text="المعرض" />
          <p class="mt-4">أحدث وأفضل أسلوب حياة لفريقنا المتميز</p>
        </div>
        <button
          class="flex items-center justify-center gap-2 py-2 ps-4 text-base lg:text-lg text-sky-400"
        >
          تصفح الكل
          <svg-icon name="chevron-left" class="fill-current w-3 h-3" />
        </button>
      </div>

      <shimmer-exhibition v-if="loading" />

      <div
        v-if="chunkedExhibitions?.four?.length"
        class="grid grid-cols-2 md:grid-cols-4 gap-6"
      >
        <div
          v-for="item in chunkedExhibitions?.four"
          :key="item?.id"
          class="rounded-2xl overflow-hidden"
        >
          <app-image
            :src="item?.image?.url"
            alt="gallery"
            ratio="2-3"
            fit="cover"
          />
        </div>
      </div>

      <div
        v-if="chunkedExhibitions?.two?.length"
        class="grid md:grid-cols-2 gap-6 mt-6"
      >
        <div
          v-for="item in chunkedExhibitions?.two"
          :key="item?.id"
          class="rounded-2xl overflow-hidden"
        >
          <app-image
            :src="item?.image?.url"
            alt="gallery"
            ratio="4-2"
            fit="cover"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {chunk} from "lodash-es";
const {exhibitions, loading} = useExhibition();

type Gallery = {
  id: string;
  image: {
    id: string;
    url: string;
  };
};

/**
 * Chunk exhibitions to 4, 2 items
 */
const chunkedExhibitions = computed(() => {
  const currentChunk = chunk(exhibitions?.value?.slice(0, 5), 4) as [
    Gallery[],
    Gallery[]
  ];

  return {
    four: currentChunk[0] || [],
    two: currentChunk[1] || [],
  };
});
</script>
