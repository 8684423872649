<template>
  <div class="bg-sky-100">
    <div class="container py-14 lg:pt-28">
      <div class="text-center mb-10">
        <p class="block text-xl lg:text-2xl text-sky-400 mb-3">شركاء النجاح</p>
        <h3 class="text-2xl lg:text-3xl font-bold text-blue-900">
          ماذا يقول عملاؤنا!
        </h3>
      </div>
      <div class="relative lg:w-3/4 mx-auto lg:pb-14">
        <swiper-wrapper
          :items="testimonials"
          :swiperOptions="swiperOptions"
          arrows
          dotted
        >
          <template v-slot="{item}">
            <div class="text-center bg-white md:mx-20 rounded-xl p-10">
              <div
                class="w-20 h-20 overflow-hidden rounded-full mx-auto border border-sky-400"
              >
                <app-image :src="item?.image?.url" alt="client" fit="cover" />
              </div>

              <h3 class="text-gray-300 font-bold mb-3 mt-4">
                {{ item?.name }}
              </h3>
              <p class="text-gray-100 mb-3">{{ item?.job }}</p>
              <p class="text-gray-300">
                {{ item?.content }}
              </p>
            </div>
          </template>
        </swiper-wrapper>

        <svg-icon
          name="quote"
          class="lg:absolute bottom-10 start-0 w-16 h-10 mt-8 lg:mt-0"
        />
        <svg-icon name="quote" class="absolute -top-20 end-0 w-16 h-10" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const swiperOptions = computed(() => {
  return {
    breakpoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
    },
    autoplay: {
      delay: 2000,
      disableOnInteraction: true,
    },
  };
});

const {testimonials} = useSuccessPartners();
</script>
