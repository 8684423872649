<template>
  <client-only>
    <div class="container py-8 lg:py-10">
      <shimmer-partners v-if="loading" />
      <swiper-wrapper v-else :items="partners" :swiperOptions="swiperOptions">
        <template v-slot="{item}">
          <div class="w-20 h-20 mx-auto">
            <app-image :src="item?.image?.url" alt="image" />
          </div>
        </template>
      </swiper-wrapper>
    </div>
  </client-only>
</template>

<script lang="ts" setup>
const swiperOptions = computed(() => {
  return {
    breakpoints: {
      0: {
        slidesPerView: 2.5,
        spaceBetween: 10,
      },
      740: {
        slidesPerView: 3.5,
        spaceBetween: 10,
      },
      900: {
        slidesPerView: 5,
        spaceBetween: 10,
      },
      1200: {
        slidesPerView: 8,
        spaceBetween: 20,
      },
    },
    navigation: {nextEl: ".swiping-next", prevEl: ".swiping-prev"},
    autoplay: {
      delay: 2000,
      disableOnInteraction: true,
    },
  };
});

const {partners, loading} = usePartners();
</script>
