<template>
  <div class="counterView py-14 lg:py-20 text-white">
    <div class="container">
      <shimmer-statistics v-if="loading" />

      <div
        v-else
        class="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6"
      >
        <p class="flex items-center justify-center gap-x-3 my-6">
          <span class="block font-bold text-3xl lg:text-5xl">
            {{ statistics?.customers }}
          </span>
          <span class="w-14 text-xl">عملاء راضون</span>
        </p>

        <p class="flex items-center justify-center gap-x-3 my-6">
          <span class="block font-bold text-3xl lg:text-5xl">
            {{ statistics?.awards }}
          </span>
          <span class="w-14 text-xl">جوائز محققه</span>
        </p>

        <p class="flex items-center justify-center gap-x-3 my-6">
          <span class="block font-bold text-3xl lg:text-5xl">
            {{ statistics?.projects }}
          </span>
          <span class="w-14 text-xl">مشروع مكتمل</span>
        </p>

        <p class="flex items-center justify-center gap-x-3 my-6">
          <span class="block font-bold text-3xl lg:text-5xl">
            +
            {{ statistics?.years }}
          </span>
          <span class="w-14 text-xl">سنين خبرة</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const {statistics, loading} = useStatistics();
</script>

<style lang="postcss" scoped>
.counterView {
  background: url("/images/counter-bg.webp") no-repeat center center;
  background-size: cover;
}
</style>
