export const useStatistics = () => {
  const {$api} = useNuxtApp();

  const {data, pending} = useAsyncData(
    "statistics",
    async () => {
      const {
        data: {
          data: {statistics},
        },
      } = await $api.get("/statistics");

      return statistics;
    },
    {server: false, lazy: false, default: () => ({})}
  );

  const statistics = computed(() => {
    return data?.value || [];
  });

  return {
    statistics,
    loading: pending,
  };
};
