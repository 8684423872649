export const useExhibition = () => {
  const {$api} = useNuxtApp();

  const {data, pending} = useAsyncData(
    "exhibitions",
    async () => {
      const {
        data: {
          data: {exhibitions},
        },
      } = await $api.get("/exhibitions");

      return exhibitions;
    },
    {server: false, lazy: false, default: () => []}
  );

  const exhibitions = computed(() => {
    return data?.value || [];
  });

  return {
    exhibitions,
    loading: pending,
  };
};
