<template>
  <div class="flex flex-col gap-y-6 items-center bg-sky-200 p-6 rounded-2xl">
    <div
      class="w-32 h-32 md:w-40 md:h-40 rounded-full bg-sky-500/20 animate-pulse"
    ></div>
    <div
      class="w-full h-14 md:h-20 rounded-3xl bg-sky-500/20 animate-pulse"
    ></div>
    <div
      class="w-3/4 h-14 md:h-20 rounded-3xl bg-sky-500/20 animate-pulse"
    ></div>
  </div>
</template>
