<template>
  <MainSection />
  <Partners />
  <AboutUs />
  <OurServices id="services" />
  <OurWorks />
  <SuccessPartners />
  <CounterView />
  <Gallery />
  <FeaturedArticles />
</template>
