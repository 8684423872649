export const useAboutUs = () => {
  const {$api} = useNuxtApp();

  const {data, pending} = useAsyncData(
    "aboutUs",
    async () => {
      const {
        data: {
          data: {aboutUs},
        },
      } = await $api.get("/about-us");

      return aboutUs;
    },
    {server: false, lazy: false, default: () => ({})}
  );

  const aboutUs = computed(() => {
    return data?.value || [];
  });

  return {
    aboutUs,
    loading: pending,
  };
};
