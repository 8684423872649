<template>
  <div class="flex flex-col gap-y-6">
    <div
      class="animate-plus bg-sky-500/20 h-14 md:h-24 rounded-2xl w-full"
    ></div>
    <div
      class="animate-plus bg-sky-500/20 h-14 md:h-24 rounded-2xl w-3/4"
    ></div>
    <div
      class="animate-plus bg-sky-500/20 h-14 md:h-24 rounded-2xl w-2/4"
    ></div>
    <div
      class="animate-plus bg-sky-500/20 h-14 md:h-24 rounded-2xl w-1/4"
    ></div>
  </div>
</template>
