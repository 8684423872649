<template>
  <client-only>
    <div class="bg-sky-100 py-14 lg:py-20">
      <div class="container">
        <div class="flex items-center justify-between mb-10">
          <AppTitle text="أبرز المقالات" />
          <nuxt-link
            to="/articles"
            class="flex items-center justify-center gap-2 py-2 ps-4 text-base lg:text-lg text-sky-400"
          >
            تصفح الكل
            <svg-icon name="chevron-left" class="fill-current w-3 h-3" />
          </nuxt-link>
        </div>

        <swiper-wrapper :items="articles" :swiperOptions="swiperOptions">
          <template v-slot="{item}">
            <NewsCard :item="item" />
          </template>
        </swiper-wrapper>
      </div>
    </div>
  </client-only>
</template>

<script lang="ts" setup>
const {articles, loading} = useArticles();

const swiperOptions = computed(() => {
  return {
    breakpoints: {
      500: {
        slidesPerView: 1.5,
        spaceBetween: 10,
      },
      740: {
        slidesPerView: 2.5,
        spaceBetween: 10,
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
    },
    autoplay: {
      delay: 2000,
      disableOnInteraction: true,
    },
  };
});
</script>
