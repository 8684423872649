export const useSuccessPartners = () => {
  const {$api} = useNuxtApp();

  const {data, pending} = useAsyncData(
    "testimonials",
    async () => {
      const {
        data: {
          data: {testimonials},
        },
      } = await $api.get("/testimonials");

      return testimonials;
    },
    {server: false, lazy: false, default: () => []}
  );

  const testimonials = computed(() => {
    return data?.value || [];
  });

  return {
    testimonials,
    loading: pending,
  };
};
