<template>
  <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
    <div
      v-for="i in 6"
      :key="i"
      class="bg-sky-500/5 p-6 rounded-2xl flex flex-col items-center gap-y-6"
    >
      <div class="w-28 h-28 rounded-full bg-sky-500/20 animate-pulse"></div>
      <div class="flex flex-col w-full gap-y-6">
        <div class="w-full h-10 rounded-full bg-sky-500/20 animate-pulse"></div>
        <div class="h-10 rounded-full bg-sky-500/20 w-2/4 animate-pulse"></div>
      </div>
    </div>
  </div>
</template>
