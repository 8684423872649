<template>
  <div class="ourWorks">
    <div class="container">
      <div class="flex items-center justify-between mb-10">
        <div>
          <AppTitle text="أعمالنا" />
        </div>
        <nuxt-link
          to="/projects"
          class="flex items-center justify-center gap-2 py-2 ps-4 text-base lg:text-lg text-sky-400"
        >
          تصفح الكل
          <svg-icon name="chevron-left" class="fill-current w-3 h-3" />
        </nuxt-link>
      </div>
    </div>

    <div
      class="relative bg-sky-200 py-14 lg:pt-20 overflow-hidden px-8 lg:px-0"
    >
      <div class="relative container pt-10">
        <shimmer-business v-if="loading" />
        <swiper-wrapper
          :items="businesses"
          :swiperOptions="swiperOptions"
          arrows
          dotted
        >
          <template v-slot="{item}">
            <div class="grid lg:grid-cols-2 lg:px-20 items-center">
              <div class="ourWorks-image w-3/4 mx-auto">
                <app-image :src="item?.image?.url" alt="image" fit="contain" />
              </div>

              <div class="lg:w-5/6">
                <p class="text-sky-400 text-lg lg:text-xl mb-4">
                  # {{ item?.business_category?.name }}
                </p>
                <h3 class="text-3xl lg:text-4xl font-bold mb-3 line-clamp-1">
                  {{ item?.name }}
                </h3>
                <p class="text-gray-400 line-clamp-2">
                  {{ item?.description }}
                </p>

                <nuxt-link
                  :to="`/projects/${item?.id}`"
                  class="btn flex w-max items-center justify-center gap-x-3 mt-8"
                >
                  المشروع كامل
                  <svg-icon name="arrow-left" class="fill-current w-8 h-3" />
                </nuxt-link>
              </div>
            </div>
          </template>
        </swiper-wrapper>

        <img
          src="/images/texture.webp"
          alt="texture"
          class="absolute -top-6 start-0"
        />
      </div>

      <img
        src="/images/texture-work.png"
        alt="texture"
        class="absolute -bottom-6 -start-6"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
const {businesses, loading} = useBusinesses();

const swiperOptions = computed(() => {
  return {
    breakpoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 30,
      },
    },
    autoplay: {
      delay: 2000,
      disableOnInteraction: true,
    },
  };
});
</script>
