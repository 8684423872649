<template>
  <div class="grid grid-cols-2 md:grid-cols-4 gap-6">
    <div
      v-for="i in 4"
      :key="i"
      class="w-full aspect-[2/3] bg-sky-500/20 animate-pulse rounded-2xl"
    ></div>
  </div>

  <div class="grid grid-cols-2 md:grid-cols-2 gap-6 mt-6">
    <div
      v-for="i in 2"
      :key="i"
      class="w-full aspect-[4/2] bg-sky-500/20 animate-pulse rounded-2xl"
    ></div>
  </div>
</template>
