<template>
  <div class="relative bg-sky-200 py-14 lg:py-20">
    <div class="px-10 md:px-20 lg:px-40" v-if="loading">
      <shimmer-about-us />
    </div>
    <div v-else class="grid lg:grid-cols-2 items-center">
      <div class="px-10 lg:px-0 mb-10 lg:mb-0 lg:w-4/5 lg:ms-auto">
        <p class="text-sky-400 text-lg lg:text-xl mb-4">عننا</p>
        <h3 class="text-3xl lg:text-4xl font-bold mb-3">
          {{ aboutUs?.title }}
        </h3>
        <p class="text-gray-400">
          {{ aboutUs?.description }}
        </p>

        <nuxt-link
          to="/contactus"
          class="btn w-fit flex items-center justify-center gap-x-3 mt-8"
        >
          تواصل معنا
          <svg-icon name="send" class="fill-current w-8 h-4" />
        </nuxt-link>
      </div>
      <div class="flex justify-center lg:justify-end max-w-full">
        <div
          class="aboutUs-imageContainer max-w-full relative flex justify-center lg:justify-end"
        >
          <div
            class="aboutUs-image lg:absolute lg:top-0 lg:start-0 block rounded-full"
          >
            <app-image
              :src="aboutUs?.image?.url"
              alt="about image"
              class="ms-auto"
            />
          </div>

          <img src="/images/BG.png" alt="bg" class="mt-20 hidden lg:block" />
        </div>
      </div>
    </div>

    <img
      src="/images/texture.webp"
      alt="texture"
      class="absolute top-10 end-10 lg:start-10"
    />
  </div>
</template>

<script lang="ts" setup>
const {aboutUs, loading} = useAboutUs();
</script>

<style lang="postcss" scoped>
.aboutUs {
  &-imageContainer {
    @apply w-full;
    @screen md {
      width: 600px;
    }
  }
  &-image {
    @apply max-w-full w-full lg:ps-10;
    @screen lg {
      width: 450px;
    }
    max-width: 100%;
  }
}
</style>
