export const useHero = () => {
  const {$api} = useNuxtApp();

  const {data, pending} = useAsyncData(
    "hero",
    async () => {
      const {
        data: {
          data: {hero},
        },
      } = await $api.get("/hero");

      return hero;
    },
    {default: () => ({})}
  );

  const hero = computed(() => {
    return data?.value || [];
  });

  return {
    hero,
    loading: pending,
  };
};
