<template>
  <div class="flex items-center gap-x-4 md:gap-x-6">
    <div
      class="w-24 h-24 md:w-32 md:h-32 shrink-0 rounded-2xl bg-sky-500/20 animate-pulse"
    ></div>
    <div class="flex flex-col gap-y-4 md:gap-y-6 w-full">
      <div class="rounded-2xl bg-sky-500/20 h-8 md:h-12"></div>
      <div class="rounded-2xl bg-sky-500/20 h-8 md:h-12 w-3/4"></div>
    </div>
  </div>
</template>
